<template>
  <div>
    <b-spinner variant="primary" class="stripe-spinner" />
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import OrganizationServiceV2 from '@/lib/organization-service-v2';

export default {
  name: 'Stripe',
  data() {
    return {};
  },

  mounted() {
    if (this.$route.query.refresh) {
      this.refresh();
    } else {
      this.$router.push('/account');
    }
  },

  methods: {
    async refresh() {
      const sessionUser = await getAuth().sessionUser();
      const organization = await OrganizationServiceV2.retrieveOrganization(sessionUser.organizationUuid);
      const response = await OrganizationServiceV2.createAccountLink(
        organization.stripeConnectedAccountId,
        organization.id,
        organization.country
      );

      window.location.href = response.accountLink;
    }
  }
};
</script>

<style scoped>
.stripe-spinner {
  width: 4rem;
  height: 4rem;
}
</style>
